import React, { Component } from "react";
import {
  Card,
  Table,
  Spin,
  Tooltip,
  Button,
  message,
  Input,
  Divider,
  AutoComplete,
} from "antd";
import { velocityUrl } from "./../../../../urls and token/velocityurl.json";
import {
  LoadingOutlined,
  LinkOutlined,
  SyncOutlined,
  DoubleLeftOutlined,
} from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import { Row, Col, Modal } from "antd";
import axios from "axios";
import utils from "utils";
import moment from "moment";

const { TextArea } = Input;

const anticon = (
  <LoadingOutlined type="loading" style={{ fontSize: 24 }} spin />
);

class QA_Process extends Component {
  state = {
    productData: [],
    pageNo: 1,
    perPage: 20,
    loading: true,
    dataLoader: true,
    totalProd: 0,
    taskStatus: false,
    comment: { text: "" },
    searchText: "",
    searchProductData: [],
    modal_for_feature: false,
    qa_data: undefined,
    total_product: null,
    fail: false,
    category_features_data: [],
    category_slug_name: "",
    filter_params: [],
    sort_string: "",
    filterKey: [{ key: "status_code", id: 1 }],
  };

  componentDidMount() {
    this.getSeachOptions();
    this.getData();
  }

  async getData() {
    try {
      let { data: response } = await axios.get(
        `${velocityUrl}/velocity/get_task_list_feat_norm?search=${
          this.state.searchText
        }&page=${this.state.pageNo}&per_page=${this.state.perPage}&sort_on=${
          this.state.sort_string
        }&status=${this.state.filter_params.join(
          ","
        )}&task=read_for_QA_of_selected_feature`,
        {
          headers: {
            Authorization: `${localStorage.getItem("auth_token")}`,
          },
        }
      );
      //console.log(response);
      await this.setState({
        totalProd: response.count,
        productData: response.results,
        dataLoader: false,
      });
    } catch (ex) {
      //console.log("catch", ex, ex.response);
      if (ex.response && ex.response.status === 401) {
        localStorage.removeItem("auth_token");
        window.location = "/auth/login";
      } else if (ex.response && ex.response.status === 403) {
        message.error("Cors Policy Error");
      } else if (ex.response && ex.response.status === 404) {
        message.error("File Not Found");
      } else if (ex.response && ex.response.status === 500) {
        message.error("Internal Server Error");
      } else if (ex.response && ex.response.status === 508) {
        message.error("Time Out");
      } else {
        message.error("Unable to fetch task list from the server");
      }
    }
  }
  async getSeachOptions() {
    try {
      let { data: response } = await axios.get(
        `${velocityUrl}/velocity/get_entity_list?task=read_for_QA_of_selected_feature`,
        {
          headers: {
            Authorization: `${localStorage.getItem("auth_token")}`,
          },
        }
      );
      await this.setState({
        searchProductData: response,
        loading: false,
      });
    } catch (ex) {
      //console.log("catch", ex, ex.response);
      if (ex.response && ex.response.status === 401) {
        localStorage.removeItem("auth_token");
        window.location = "/auth/login";
      } else if (ex.response && ex.response.status === 403) {
        message.error("Cors Policy Error");
      } else if (ex.response && ex.response.status === 404) {
        message.error("File Not Found");
      } else if (ex.response && ex.response.status === 500) {
        message.error("Internal Server Error");
      } else if (ex.response && ex.response.status === 508) {
        message.error("Time Out");
      } else {
        message.error("Unable to fetch task list from the server");
      }
    }
  }

  async onSelect(value) {
    await this.setState({
      pageNo: 1,searchText:value
    });
    await this.getData();
  }
  async onSearch(event) {
    await this.setState({
      pageNo: 1,
    });
    await this.getData();
  }

  async onChange(value) {
    await this.setState({
      searchText: value,
    });
    if (!this.state.searchText) {
      await this.getData();
    }
  }

  getCategoryFeatures = async (cat_slug) => {
    try {
      let { data: category_feature } = await axios.get(
        `${velocityUrl}/velocity/get_category_features/${cat_slug}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("auth_token")}`,
          },
        }
      );
      let cat_feat = category_feature.data.split(",");
      this.setState({
        category_features_data: cat_feat,
      });

      //console.log(this.state.category_features_data);
    } catch (ex) {
      //console.log("error", ex);
      if (ex.response && ex.response.status === 401) {
        localStorage.removeItem("auth_token");
        window.location = "/auth/login";
      } else if (ex.response && ex.response.status === 403) {
        message.error("Cors Policy Error");
      } else if (ex.response && ex.response.status === 404) {
        message.error("File Not Found");
      } else if (ex.response && ex.response.status === 500) {
        message.error("Internal Server Error");
      } else if (ex.response && ex.response.status === 508) {
        message.error("Time Out");
      } else {
        message.error("Unable to fetch category features ");
      }
    }
  };

  handleChange = (e) => {
    let comment = { ...this.state.comment };
    comment.text = e.target.value;
    //console.log(comment);
    this.setState({ comment });
  };

  updateFailStatus = async () => {
    let { qa_data, comment } = this.state;
    //console.log(comment);
    try {
      qa_data.status_code = "error";
      let post_data = {};
      post_data["task_id"] = qa_data["task_id"];
      post_data["entity_type"] = qa_data["entity_type"];
      post_data["entity_id_type"] = qa_data["entity_id_type"];
      post_data["entity_id"] = qa_data["entity_id"];
      post_data["task"] = qa_data["task"];
      post_data["status_code"] = "error";
      post_data["error"] = comment.text;

      let manual_feature_data = {};
      manual_feature_data["entity_type"] = qa_data["entity_type"];
      manual_feature_data["entity_id_type"] = qa_data["entity_id_type"];
      manual_feature_data["entity_id"] = qa_data["entity_id"];
      manual_feature_data["task"] = "manual_feature_norm_surface_form";
      manual_feature_data["status_code"] = "new";
      manual_feature_data["error"] = comment.text;

      let { data: renew_feature } = await axios.put(
        `${velocityUrl}/velocity/update_task`,
        post_data,
        {
          headers: {
            Authorization: `${localStorage.getItem("auth_token")}`,
          },
        }
      );

      let { data: updateTask } = await axios.put(
        `${velocityUrl}/velocity/update_or_create_task`,
        manual_feature_data,
        {
          headers: {
            Authorization: `${localStorage.getItem("auth_token")}`,
          },
        }
      );

      //console.log("update task", updateTask);
      //console.log("updat", renew_feature);

      message.success("Task Updated Successfully");
      await this.getData()
    } catch (ex) {
      //console.log("catch", ex, ex.response);
      if (ex.response && ex.response.status === 401) {
        localStorage.removeItem("auth_token");
        window.location = "/auth/login";
      } else if (ex.response && ex.response.status === 403) {
        message.error("Cors Policy Error");
      } else if (ex.response && ex.response.status === 404) {
        message.error("File Not Found");
      } else if (ex.response && ex.response.status === 500) {
        message.error("Internal Server Error");
      } else if (ex.response && ex.response.status === 508) {
        message.error("Time Out");
      } else {
        message.error("Unable to update task");
      }
    }
  };

  refreshPage = async () => {
    await this.setState({ dataLoader: true, page: 1 });
    await this.getData();
  };

  handleModel_for_feature = async (row) => {
    this.setState({
      category_slug_name: row.entity_id,
    });
    this.getCategoryFeatures(row.entity_id);
    //console.log("row", row);
    //
    try {
      let { data: response } = await axios.get(
        `${velocityUrl}/velocity/get_task_byID/${row.task_id}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("auth_token")}`,
          },
        }
      );

      //console.log("respo", response);
      await this.getData()
    } catch (ex) {
      //console.log("catch", ex, ex.response);
      if (ex.response && ex.response.status === 401) {
        localStorage.removeItem("auth_token");
        window.location = "/auth/login";
      } else if (ex.response && ex.response.status === 403) {
        message.error("Cors Policy Error");
      } else if (ex.response && ex.response.status === 404) {
        message.error("File Not Found");
      } else if (ex.response && ex.response.status === 500) {
        message.error("Internal Server Error");
      } else if (ex.response && ex.response.status === 508) {
        message.error("Time Out");
      } else {
        message.error("Unable to create a new task by id ");
      }
    }

    this.setState({ modal_for_feature: true, qa_data: row });
  };
  viewModel_for_feature = () => {
    this.setState({ modal_for_feature: false });
  };

  handlefail = (view) => {
    this.setState({ fail: view });
  };

  PassTask = async () => {
    let { category_slug_name, qa_data } = this.state;
    let post_data = {};
    post_data["task_id"] = qa_data["task_id"];
    post_data["entity_type"] = qa_data["entity_type"];
    post_data["entity_id_type"] = qa_data["entity_id_type"];
    post_data["entity_id"] = qa_data["entity_id"];
    post_data["task"] = qa_data["task"];
    post_data["status_code"] = "success";
    post_data["error"] = "";
    let { data: updateTask } = await axios.put(
      `${velocityUrl}/velocity/update_task`,
      post_data,
      {
        headers: {
          Authorization: `${localStorage.getItem("auth_token")}`,
        },
      }
    );
    //console.log(updateTask, "//console.log");

    try {
      let { data: response } = await axios.put(
        `${velocityUrl}/velocity/update_or_create_task`,
        {
          entity_type: "category",
          entity_id_type: "category_slug",
          entity_id: category_slug_name,
          task: "compute_sentiment_scoring_product_scoring_category",
          status_code: "new",
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("auth_token")}`,
          },
        }
      );
      message.success(`Task Passed  Successfully`);
      await this.getData()
    } catch (ex) {
      //console.log("catch", ex, ex.response);
      if (ex.response && ex.response.status === 401) {
        localStorage.removeItem("auth_token");
        window.location = "/auth/login";
      } else if (ex.response && ex.response.status === 403) {
        message.error("Cors Policy Error");
      } else if (ex.response && ex.response.status === 404) {
        message.error("File Not Found");
      } else if (ex.response && ex.response.status === 500) {
        message.error("Internal Server Error");
      } else if (ex.response && ex.response.status === 508) {
        message.error("Time Out");
      } else {
        message.error("Unable to passed task ");
      }
    }
  };
  paginationFunc = async (paginator, filters, sorter, extra) => {
    if (sorter.order) {
      await this.setState({
        dataLoader: true,
        pageNo: extra.action === "paginate" ? paginator.current : 1,
        sort_string:
          sorter.order === "ascend"
            ? sorter.field
            : sorter.order === "descend"
            ? "-" + sorter.field
            : "",
      });
    }

    await this.setState({
      dataLoader: true,
      pageNo: extra.action === "paginate" ? paginator.current : 1,
      filter_params: filters[1] ? filters[1] : [],
    });

    await this.getData();
  };
  render() {
    const {
      pageNo,
      loading,
      dataLoader = true,
      perPage,
      searchText,
      searchProductData,
      totalProd = 0,
      qa_data,
      category_features_data,
      productData = [],
    } = this.state;

    const tableColumns = [
      {
        title: "Task ID",
        dataIndex: "task_id",
        sorter: (a, b) => utils.antdTableSorter(0, 0, "task_id"),
      },

      {
        title: "Entity Type",
        dataIndex: "entity_type",
      },
      {
        title: "Entity ID Type",
        dataIndex: "entity_id_type",
      },
      {
        title: "Entity Id",
        dataIndex: "entity_id",
      },
      {
        title: "Task",
        dataIndex: "task",
      },

      {
        title: "Updated Time",
        dataIndex: "update_time",
        align: "center",
        sorter: (a, b) => utils.antdTableSorter(0, 0, "update_time"),
        render: (text, row, index) => {
          return <span className="text-success">{moment(text).format("DD MMMM YYYY")}</span>;
        },
      },
      {
        title: "Updated By",
        dataIndex: "user",
        align: "center",
      },
      {
        title: "Status Code",
        dataIndex: "status_code",
        align: "center",
        width: "150",
        key: "1",
        filters: [
          {
            text: "New",
            value: "new",
          },
          {
            text: "Assigned",
            value: "assigned",
          },
          {
            text: "Error",
            value: "error",
          },
          {
            text: "Success",
            value: "success",
          },
        ],
        filterMultiple: true,
        render: (text, row, index) => {
          return <>{text} </>;
        },
      },
      {
        title: "List of Features",
        dataIndex: "",
        align: "center",
        fixed: "right",
        render: (text, row, index) => {
          return (
            <>
              <Tooltip title={"Review Features"}>
                <Button
                  onClick={() => this.handleModel_for_feature(row)}
                  type="primary"
                  disabled={row.status_code !== "new"}
                  icon={<LinkOutlined />}
                  size={"small"}
                />
              </Tooltip>
            </>
          );
        },
      },
    ];
    //console.log(this.state);
    return (
      <>
        {!loading ? (
          <Card>
            {/* <div className="row">
              <div className="col text-right">
                <SyncOutlined
                  onClick={this.refreshPage}
                  style={{ color: "blue" }}
                />
              </div>
            </div> */}

            {this.state.modal_for_feature && (
              <Modal
                afterClose={this.viewModel_for_feature}
                onCancel={this.viewModel_for_feature}
                title={
                  <span>
                    List of the Features <br />
                    <span style={{ fontSize: "12px" }}>
                      Task Id :{" "}
                      <span style={{ color: "blue" }}>{qa_data.task_id}</span>
                      <span style={{ paddingLeft: "15px" }}>
                        Entity_id:{" "}
                        <a
                          style={{ color: "blue" }}
                          target="_blank"
                          href={`https://www.amazon.com/s?k=${qa_data.entity_id}`}
                        >
                          {qa_data.entity_id}
                        </a>
                      </span>
                    </span>
                  </span>
                }
                visible={this.state.modal_for_feature}
                closable={true}
                footer={null}
                width={800}
                style={{ top: "150px" }}
                destroyOnClose
              >
                <ul>
                  <div className="row">
                    {category_features_data.map((m1) => {
                      return (
                        <div key={m1} className="col-6">
                          {m1}
                        </div>
                      );
                    })}
                  </div>
                </ul>
                <Divider className="mt-0" />
                {!this.state.fail && (
                  <div>
                    <Button
                      size="small"
                      style={{ background: "red", color: "white" }}
                      onClick={() => this.handlefail(true)}
                    >
                      Fail
                    </Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      onClick={this.PassTask}
                      size="small"
                      type="primary"
                    >
                      Pass
                    </Button>
                  </div>
                )}

                {this.state.fail && (
                  <div>
                    <TextArea
                      value={this.state.comment.text}
                      onChange={this.handleChange}
                      row={"3"}
                      placeholder="Type your feedback here..."
                    />
                    <Button
                      size="small"
                      onClick={() => this.handlefail(false)}
                      style={{ marginTop: "12px", color: "green" }}
                      type="ghost"
                    >
                      <DoubleLeftOutlined />
                    </Button>
                    <Button
                      onClick={this.updateFailStatus}
                      style={{ marginTop: "5px", marginLeft: "10px" }}
                      size="small"
                      type="primary"
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </Modal>
            )}

            <Flex
              alignItems="center"
              justifyContent="between"
              mobileFlex={false}
            >
              <Flex className="mb-1" mobileFlex={false}>
                <div className="mr-md-3 ">
                  {" "}
                  <AutoComplete
                    dropdownMatchSelectWidth={252}
                    style={{
                      width: 500,
                    }}
                    options={searchProductData.map((n) => ({
                      value: n,
                      label: n,
                    }))}
                    filterOption={true}
                    onChange={(value) => this.onChange(value)}
                    onSelect={(value) => this.onSelect(value)}
                    placeholder={searchText}
                    value={searchText}
                  >
                    <Input.Search
                      onPressEnter={(event) => this.onSearch(event)}
                    />
                  </AutoComplete>
                </div>
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="between"
                mobileFlex={false}
              >
                <Row type="flex" justify="end">
                  <Col></Col>
                </Row>
                <div> &nbsp;&nbsp;</div>
                <div></div>
              </Flex>
            </Flex>
            <div className="table-responsive mt-3">
              <Table
                dataSource={productData}
                columns={tableColumns}
                rowKey="task_id"
                loading={dataLoader}
                scroll={{ x: 1500 }}
                onChange={(paginator, filters, sorter, extra) =>
                  this.paginationFunc(paginator, filters, sorter, extra)
                }
                pagination={{
                  current: pageNo,
                  total: totalProd,
                  pageSize: perPage,
                  showSizeChanger: true,
                  onShowSizeChange: async (page, perPage) => {
                    await this.setState({
                      pageNo: 1,
                      perPage: perPage,
                      dataLoader: true,
                    });
                    await this.getData();
                  },
                }}
              />
              <h5 className="text-right">Total Products {totalProd}</h5>
            </div>
          </Card>
        ) : (
          <Spin indicator={anticon} />
        )}
      </>
    );
  }
}

export default QA_Process;
