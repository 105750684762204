import React, { useState } from "react";
import { connect } from "react-redux";
import { Menu, Layout, Tooltip, Dropdown } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CarryOutOutlined,
  CustomerServiceOutlined,
  RetweetOutlined,
  RiseOutlined,
  ArrowsAltOutlined,
  ApartmentOutlined,
  DeleteOutlined,
  InfoOutlined,
  StockOutlined,
  OneToOneOutlined,
  CaretDownOutlined,
  ProjectOutlined
} from "@ant-design/icons";
import Logo from "./Logo";
import NavPanel from "./NavPanel";
import NavSearch from "./NavSearch";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import { Link } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';
import UserProfile from "./user_profile";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
  } = props;
  if (document.getElementById("handle-pros-cons") && navCollapsed) {
    document.getElementById("handle-pros-cons").style.left = "106px;"
  } else if (document.getElementById("handle-pros-cons") && !navCollapsed) {
    document.getElementById("handle-pros-cons").style.left = "276px;"
  }
  const [searchActive, setSearchActive] = useState(false);
  const username = `${localStorage.getItem("username")}`;
  const role = `${localStorage.getItem("role")}`;
  const [toggle, setToggle] = useState(false);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = async () => {

    if (!toggle) {
      await setToggle(true);
    } else {
      await setToggle(false);
    }

    try{
      if(toggle){
        if(document.getElementById('stickybar') !== null){
          document.getElementById("stickybar").style.marginLeft="0%"
        }

        if(document.getElementById('mlprompt') !== null){
          document.getElementById("mlprompt").style.left="68%"
          document.getElementById("mlprompt").style.top="50%"
          document.getElementById("mlprompt").style.width="95%"
        }
      }else{
        if(document.getElementById('stickybar') !== null){
          document.getElementById("stickybar").style.marginLeft="10.5rem"
        }
        if(document.getElementById('mlprompt') !== null){
          document.getElementById("mlprompt").style.left="72%"
          document.getElementById("mlprompt").style.top="50%"
          document.getElementById("mlprompt").style.width="84%"
        }
      }
    } catch (ex) {
      console.log(ex)
    }
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = utils.getColorContrast(headerNavColor);
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  const menu = (
    <Menu>
      <UserProfile />
    </Menu>
  );
  return (
    <Header
      className={`app-header ${mode}`}
      style={{ backgroundColor: headerNavColor,height:"58px" }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={mode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <Menu mode="horizontal">
              {isNavTop && !isMobile ? null : (
                <Menu.Item
                  key="0"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </Menu.Item>
              )}
              {/* <Menu.Item key="1">
                <Tooltip title="Category Assign Board">
                  <Link to={`/app/dashboard/task-board`}>
                    <CarryOutOutlined className="nav-icon" />
                  </Link>
                </Tooltip>
              </Menu.Item> */}

              {/* <Menu.Item>
              <Link to={`/app/contextsummary`}>
                <CustomerServiceOutlined />
              </Link>
              </Menu.Item> */}
              {/* {localStorage.getItem("superuser") === "true" &&

                <Menu.Item>
                  <Tooltip title="Move Your Product">
                    <Link to={`/app/moveproducts`}>
                      <RetweetOutlined style={{ fontSize: 24 }} />
                    </Link>
                  </Tooltip>
                </Menu.Item>} */}

              <Menu.Item>
                {/* <Tooltip title="Daily deals">
                  <Link to={`/app/dailysales`}>
                    <RiseOutlined style={{ fontSize: 24 }} />
                  </Link>
                </Tooltip> */}

              </Menu.Item>
              {/* <Menu.Item>
                <Tooltip title="Ebay QA">
                  <Link to={`/app/ebayqa`}>
                    <ArrowsAltOutlined style={{ fontSize: 24 }} />
                  </Link>
                </Tooltip>
              </Menu.Item> */}

              {/* <Menu.Item>
                <Tooltip title="Child Categories">
                  <Link to={`/app/childcategory`}>
                    <ApartmentOutlined style={{ fontSize: 24 }} />
                  </Link>
                </Tooltip>
              </Menu.Item> */}

              {/* {localStorage.getItem("superuser") === "true" &&
                <Menu.Item>
                  <Tooltip title="Delete Products">
                    <Link to={`/app/delete_asins`}>
                      <DeleteOutlined style={{ fontSize: 24 }} />
                    </Link>
                  </Tooltip>
                </Menu.Item>

              } */}

              {/* <Menu.Item>
                <Tooltip title="Generate Products Report">
                  <Link to={`/app/product_report`}>
                    <InfoOutlined style={{ fontSize: 24 }} />
                  </Link>
                </Tooltip>
              </Menu.Item> */}

              {/* <Menu.Item>
                <Tooltip title="Upload Sold Details">
                  <Link to={`/app/soldproductdtails`}>
                    <StockOutlined style={{ fontSize: 24 }} />
                  </Link>
                </Tooltip>
              </Menu.Item> */}

              {/* {localStorage.getItem("superuser") === "true" &&
              <Menu.Item>
                <Tooltip title="Edit OpenAI Prompts">
                  <Link to={`/app/openaiprompt`}>
                    <OneToOneOutlined style={{ fontSize: 24 }} />
                  </Link>
                </Tooltip>
              </Menu.Item>
             }
             {localStorage.getItem("superuser") === "true" &&
              <Menu.Item>
                <Tooltip title="Add Edit Facet Data">
                  <Link to={`/app/facetcontrol`}>
                    <ProjectOutlined style={{ fontSize: 24 }} />
                  </Link>
                </Tooltip>
              </Menu.Item>
             } */}
            </Menu>
          </div>
          <div className="nav-right">
            <div className="row d-flex justify-content-between align-items-center mr-2" >
              <strong style={{fontSize: "16px",fontWeight: "600",marginRight:"4px"}}>{localStorage.getItem("username")}</strong>
              <Dropdown overlay={menu} trigger="click" style={{ width: "200px", marginLeft: "5px" }}>
                <CaretDownOutlined style={{ color: "black", fontSize: "10px", cursor: "pointer" }} />
              </Dropdown>
            </div>

            {/* <span>
              <span style={{ fontWeight: "100" }}>Username</span>:
              <span className="ml-1" style={{ fontSize: "1.034rem" }}>
                {username}
              </span>
            </span>
            <span className="ml-3">
              <span style={{ fontWeight: "100" }}>Role</span>:
              <span className="ml-1" style={{ fontSize: "1.034rem" }}>
                {role}
              </span>
            </span> */}
            {/* <NavPanel /> */}
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
