import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";

function App() {
  useEffect(() => {
    ReactGA.initialize("UA-134286747-5");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  var DEBUG = false;
  // ENABLE/DISABLE Console Logs
  if (!DEBUG) {
    window.console = {};
    window.console.log = function () {
    };
    window.console.debug = function () {
    };
    window.console.info = function () {
    };
    window.console.warn = function () {
    };
    window.console.error = function () {
    };
  }
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
