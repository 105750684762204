import {
  DashboardOutlined,
  UnorderedListOutlined,
  CoffeeOutlined,
  PicLeftOutlined,
  MacCommandOutlined,
  AuditOutlined,
  BulbOutlined,
  QqOutlined,
  MessageOutlined,
  CodeSandboxOutlined,
  RightCircleOutlined,
  FormOutlined,
  HomeOutlined,
  BoxPlotOutlined,
  BgColorsOutlined,
  AppstoreAddOutlined,
  DownloadOutlined,
  HighlightOutlined,
  CustomerServiceOutlined,
  CarryOutOutlined,
  RiseOutlined,
  CheckCircleOutlined,
  FileTextOutlined,
  SecurityScanOutlined,
  FileSearchOutlined,
  RadarChartOutlined,
  FileZipOutlined,
} from "@ant-design/icons";

import { APP_PREFIX_PATH } from "configs/AppConfig";
import QA_Process from "views/app-views/category processing/QA Process/qa_for_selected_feature";
const role = localStorage.getItem("role");
console.log(APP_PREFIX_PATH);

const dashBoardNavTree = [
  // {
  //   key: "home",
  //   path: `${APP_PREFIX_PATH}/home`,
  //   title: "home",
  //   icon: DashboardOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "scrumboard",
    title: "Scrumboard",
    icon: BulbOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "scrumboard",
        path: `${APP_PREFIX_PATH}/category_processing/scrumboard`,
        title: "Scrumboard",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "scrumboard_version2",
        path: `${APP_PREFIX_PATH}/category_processing/scrumboard_version2`,
        title: "Scrumboard_Version2",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "snippet_scrumboard",
        path: `${APP_PREFIX_PATH}/category_processing/snippet_scrumboard`,
        title: "Snippet Scrumboard",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "pros_cons",
    title: "Pros Cons",
    icon: AppstoreAddOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "Category Assign Board",
        path: `${APP_PREFIX_PATH}/dashboard/task-board`,
        title: "Pros Cons Board",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Missing_pros_cons",
        path: `${APP_PREFIX_PATH}/missing_top_pros_cons`,
        title: "Missing Top Pros Cons",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "QA_Process",
    title: "QA Process",
    icon: CheckCircleOutlined,
    submenu: [
      {
        key: "ebayqa",
        path: `${APP_PREFIX_PATH}/ebayqa`,
        title: "Affiliates QA",
        icon: BoxPlotOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "CTA_QA_List",
        path: `${APP_PREFIX_PATH}/cta_qa_list`,
        title: "CTA QA List",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "QA_Process",
        path: `${APP_PREFIX_PATH}/category_processing/qa_testing_list`,
        title: "QA Process",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "QA for Selected Feature",
        path: `${APP_PREFIX_PATH}/category_processing/qa_for_selected_feature`,
        title: "Selected Feature QA",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "affiliate_product_mapping_qa",
        path: `${APP_PREFIX_PATH}/affiliate_product_mapping_qa`,
        title: "Affiliate Product Mapping",
        icon: BoxPlotOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "category_release",
        path: `${APP_PREFIX_PATH}/category_release`,
        title: "Category Release",
        icon: BoxPlotOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "Feature_Normalize_List",
    path: `${APP_PREFIX_PATH}/category_processing/feat_norm_task_list`,
    title: "Feature Normalize List",
    icon: CoffeeOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "Content",
    title: "Category Description",
    icon: CarryOutOutlined,
    submenu: [
      {
        key: "Category_Description_Assign",
        path: `${APP_PREFIX_PATH}/description_board/category_desc_assign`,
        title: "Category Description Assignment",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Category_Description",
        path: `${APP_PREFIX_PATH}/category_description/list_page`,
        title: "Category Description",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Mix_Category_Page",
        path: `${APP_PREFIX_PATH}/mixcatepage`,
        title: "Mix Category Page",
        icon: BoxPlotOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "SEM_Landing_Page",
    title: "SEM Landing Page",
    icon: FileTextOutlined,
    submenu: [
      {
        key: "SEM_Landing_Page_1",
        path: `${APP_PREFIX_PATH}/sem_landing_page_1`,
        title: "Landing Page 1",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "SEM_Landing_Page_2",
        path: `${APP_PREFIX_PATH}/sem_landing_page_2`,
        title: "Landing Page 2",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "Home Page",
    title: "Home Page Design",
    icon: HomeOutlined,
    submenu: [
      {
        key: "Home_Page_Modular2",
        path: `${APP_PREFIX_PATH}/m2homepage`,
        title: "Home Page Modular",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "popular_categories",
        path: `${APP_PREFIX_PATH}/popular_categories`,
        title: "Popular Categories",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Home_Page_Theme",
        path: `${APP_PREFIX_PATH}/homepagetheme`,
        title: "Home Page Theme",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Home_Page_Ad_Banner",
        path: `${APP_PREFIX_PATH}/homepagead`,
        title: "Home Page Ad Banner",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Home_Page_Footer",
        path: `${APP_PREFIX_PATH}/homepagefooter`,
        title: "Home Page Head Footer",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "affiliates_data",
    title: "Affiliates UI",
    icon: RadarChartOutlined,
    submenu: [
      {
        key: "Set_Merchant_Priority",
        title: "Set Merchant Stauts",
        path: `${APP_PREFIX_PATH}/merchant_priority`,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "daily_deals",
    title: "Daily Deals & Sales",
    icon: RiseOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "Daily_Deals_All_Data",
        path: `${APP_PREFIX_PATH}/dailysales`,
        title: "Daily Deals All Data",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Daily_Deals_QA_Data",
        path: `${APP_PREFIX_PATH}/daily-deals-product-qa/category-page`,
        title: "Daily Deals QA Data",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "manage_deals_page",
        path: `${APP_PREFIX_PATH}/manage_deals_page`,
        title: "Manage Deals Page",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },

  // {
  //   key: "Research_List",
  //   path: `${APP_PREFIX_PATH}/research/research_list`,
  //   title: "Research_List",
  //   icon: PicLeftOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },

  {
    key: "Miscellaneous",
    title: "Miscellaneous",
    icon: MessageOutlined,
    submenu: [
      {
        key: "Blog",
        path: `${APP_PREFIX_PATH}/bvrblog`,
        title: "Blog",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Chat",
        path: `${APP_PREFIX_PATH}/chat`,
        title: "Chat",
        breadcrumb: false,
      },
    ],
  },
];

//Author :- Abhay Kumar Jha 
// Date :- 27/Oct/2023
// Function :- To add a new button in submeny of QA Process
// Define the new object to be added conditionally
const unzipFolderObject = {
  key: "unzip_folder_on_remote_machine",
  path: `${APP_PREFIX_PATH}/unzip_folder_on_remote_machine`,
  title: "Unzip File",
  icon: FileZipOutlined,
  breadcrumb: false,
  submenu: [],
};

// Find the "QA Process" section and its submenu
const qaProcessSection = dashBoardNavTree.find(
  (item) => item.key === "QA_Process"
);

if (qaProcessSection) {
  // Check the condition and add the new object if it's met
  if (localStorage.getItem("role") === "Manager" || localStorage.getItem("role") === "User") {
    qaProcessSection.submenu.push(unzipFolderObject);
  }   
}

// End of To add a new button in submeny of QA Process 

const management = {
  key: "Category",
  title: "Category Management",
  icon: SecurityScanOutlined,
  submenu: [
    {
      key: "all_task_list",
      path: `${APP_PREFIX_PATH}/category_processing/all_task_list`,
      title: "All Task List",
      breadcrumb: false,
    },
    {
      key: "Category_List",
      path: `${APP_PREFIX_PATH}/category/category_list`,
      title: "Category List",
      breadcrumb: false,
    },
    {
      key: "Specs_ui",
      path: `${APP_PREFIX_PATH}/category/get_specification_generation_set`,
      title: "Specification Sets",
      breadcrumb: false,
    },
    {
      key: "Add_Category_from_XLSX",
      path: `${APP_PREFIX_PATH}/add_category`,
      title: "Add Categories in Bulk",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "Add_Amazon_Product_Pipeline_from_XLSX",
      path: `${APP_PREFIX_PATH}/amazon_product`,
      title: "Add Amazon Products",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "Add_Jungle_Scout_from_CSV",
      path: `${APP_PREFIX_PATH}/add_jungle_scout_data`,
      title: "Add Jungle Scout Products",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "child_category",
      path: `${APP_PREFIX_PATH}/childcategory`,
      title: "Child Categories",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "manage_bread_crumb",
      path: `${APP_PREFIX_PATH}/manage_bread_crumb`,
      title: "Manage BreadCrumb",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "category_blog_js",
      path: `${APP_PREFIX_PATH}/category_blog_js`,
      title: "Category Blog JS",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "snippet_generation",
      path: `${APP_PREFIX_PATH}/category/snippet_generation`,
      title: "Snippet Generation",
      breadcrumb: false,
    },
    {
      key: "snippet_QA",
      path: `${APP_PREFIX_PATH}/category/snippet_QA`,
      title: "Snippet QA",
      breadcrumb: false,
    },
  ],
};

{
  localStorage.getItem("superuser") === "true" &&
  management.submenu.push(
    {
      key: "Move_Product",
      path: `${APP_PREFIX_PATH}/moveproducts`,
      title: "Product Migration",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "Delete_products",
      path: `${APP_PREFIX_PATH}/delete_asins`,
      title: "Delete Products",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "Blacklist_Products",
      path: `${APP_PREFIX_PATH}/blacklist_page`,
      title: "Blacklist",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "Facet",
      path: `${APP_PREFIX_PATH}/facetcontrol`,
      title: "Add & Edit Facets Data",
      breadcrumb: false,
      submenu: [],
    },
    {
      key:"create_aspect_and_tagline",
      path:`${APP_PREFIX_PATH}/create_aspect_and_tagline`,
      title:"Edit Aspect & Reviews",
      breadcrumb: false,
      submenu:[]
    }

  )
  dashBoardNavTree.splice(1, 0, management)
}

const elastic_UI = {
  key: "elastic_search",
  title: "Elastic Search",
  path: `${APP_PREFIX_PATH}/elastic_search`,
  icon: FileSearchOutlined,
  breadcrumb: false,
  submenu: [],
};
dashBoardNavTree.splice(9, 0, elastic_UI);
const report_pannel = {
  key: "report",
  title: "Reports",
  icon: DownloadOutlined,
  submenu: [
    {
      key: "scrumboard_report",
      path: `${APP_PREFIX_PATH}/scrumboard_report`,
      title: "Scrumboard Report",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "sold_details",
      path: `${APP_PREFIX_PATH}/soldproductdtails`,
      title: "Upload Daily Sales",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "SEM",
      path: `${APP_PREFIX_PATH}/sem_report`,
      title: "SEM Report",
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "product_report",
      path: `${APP_PREFIX_PATH}/product_report`,
      title: "Generate Products Report",
      breadcrumb: false,
      submenu: [],
    },
  ],
};

{
  localStorage.getItem("superuser") === "true" &&
    report_pannel.submenu.push({
      key: "Edit_Prompts",
      path: `${APP_PREFIX_PATH}/openaiprompt`,
      title: "Edit OpenAI Prompts",
      breadcrumb: false,
      submenu: [],
    });
  dashBoardNavTree.splice(5, 0, report_pannel);
}

const DemoDashBoardNavTree = [
  {
    key: "Feature_Normalize_List",
    path: `${APP_PREFIX_PATH}/category_processing/feat_norm_task_list`,
    title: "Feature_Normalize_List",
    icon: CoffeeOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig =
  role === "Demo" ? [...DemoDashBoardNavTree] : [...dashBoardNavTree];

export default navigationConfig;
