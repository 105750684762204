import React, { Component } from 'react';
import "./style.css"
import { Modal, Input, message } from 'antd'
import { UserOutlined, SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import axios from 'axios'
import { velocityUrl } from '../../urls and token/velocityurl.json'
class UserProfile extends Component {
    state = {
        validate: false,
        visible: false,
        reset_button: false,
        passwordMessage: "",
        data: { oldPassword: "", newPasword: "", retypePassword: "" },
        error: { oldPassword: "This Can't be empty", newPasword: "This Can't be empty", retypePassword: "This Can't be empty" }
    }

    showModal = () => {
        this.setState({ visible: true, data: { oldPassword: "", newPasword: "", retypePassword: "" }, error: { oldPassword: "This Can't be empty", newPasword: "This Can't be empty", retypePassword: "This Can't be empty" } })
    };


    handleCancel = () => {
        this.setState({ visible: false, data: { oldPassword: "", newPasword: "", retypePassword: "" }, error: { oldPassword: "This Can't be empty", newPasword: "This Can't be empty", retypePassword: "This Can't be empty" } })
    }
    showReset = () => {
        this.setState({ reset_button: true, data: { oldPassword: "", newPasword: "", retypePassword: "" }, error: { oldPassword: "This Can't be empty", newPasword: "This Can't be empty", retypePassword: "This Can't be empty" } })
    };

    resetStart = async () => {
        this.resetPassword()
    }

    resetPassword = async () => {
        try {
            let temp = { username: localStorage.getItem("username"), password: this.state.data.oldPassword, new_password: this.state.data.newPasword }
            let { data: response } = await axios.put(
                `${velocityUrl}/velocity/set_new_password`,
                temp,
                {
                    headers: {
                        Authorization: `${localStorage.getItem("auth_token")}`,
                    },
                }
            );
            console.log(response)
            if (response.status) {
                localStorage.removeItem("auth_token");
                localStorage.removeItem("role");
                localStorage.removeItem("username");
                window.location = "/auth/login";
            }

            this.setState({ passwordMessage: response })

        } catch (ex) {
            if (ex.response && ex.response.status === 401) {
                localStorage.removeItem("auth_token");
                window.location = "/auth/login";
            } else if (ex.response && ex.response.status === 403) {
                message.error("Cors Policy Error");
            } else if (ex.response && ex.response.status === 404) {
                message.error("File Not Found");
            } else if (ex.response && ex.response.status === 500) {
                message.error("Internal Server Error");
            } else if (ex.response && ex.response.status === 508) {
                message.error("Time Out");
            } else {
                message.error("Unable to fetch product details");
            }
        }
    };

    resetAbort = () => {
        this.setState({ reset_button: false, data: { oldPassword: "", newPasword: "", retypePassword: "" }, error: { oldPassword: "This Can't be empty", newPasword: "This Can't be empty", retypePassword: "This Can't be empty" } })
    }
    removeToken = () => {
        localStorage.removeItem("username")
        localStorage.removeItem("auth_token")
        window.location = "/auth/login"
    }
    createField = (name, value, error, placeholder) => {
        return (
            <div className='row col-12 flex-column m-1'>
                {name === "oldPassword" ?
                    <Input value={value} name={name} placeholder={placeholder} onChange={this.handleChange} />
                    :
                    <Input.Password value={value} name={name} placeholder={placeholder} onChange={this.handleChange} />
                }
                {name === "newPasword" ? <span className={error === 1 ? "text-success" : error === 3 ? "text-warning" : 'text-danger'}>
                    {error === 1 ? "Password Is Strong" : error === 3 ? "Password Is Medium" : error === 2 ? "Password Is Weak" : "Password Length Should be > 8."}
                </span> : error && <span className='text-danger'>{error}</span>}
            </div>
        )
    }
    handleChange = (e) => {
        let temp = e.currentTarget;
        let Copy = { ...this.state }
        Copy.data[temp.name] = temp.value
        this.setState(Copy)
        this.UpdateError()
    }
    UpdateError = () => {
        let data = this.state.data
        let error = {}
        error.oldPassword = data.oldPassword.length > 0 ? "" : "This field can't be empty"
        error.newPasword = data.newPasword.length >= 8 ? this.checkPasswordStrength(data.newPasword) : 0
        error.retypePassword = data.retypePassword.length >= 8 ? data.newPasword !== data.retypePassword ? "Password Is Not Matched" : "" : "Type Same New Password Here"
        this.setState({ error: error, validate: !error.oldPassword && error.newPasword === 1 && !error.retypePassword ? true : false })
    }
    checkPasswordStrength = (newPass) => {
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
        if (strongPassword.test(newPass)) {
            return 1
        } else if (mediumPassword.test(newPass)) {
            return 3
        } else {
            return 2
        }

    }
    render() {
        const { data = {}, error = {}, visible = false, reset_button = false, validate = false, passwordMessage = "" } = this.state
        return (
            <>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile" style={{ width: "260px", height: "auto" }}>
                    <div className='row d-flex justify-content-center align-items-center mt-2'>
                        <div className='row col-6 d-flex justify-content-center align-items-center'><UserOutlined style={{ fontSize: "60px", color: "#ffffff", backgroundColor: "rgb(38 119 203)", borderRadius: "50%", padding: "12px" }} /></div>
                        <div className='row flex-cloumn justify-content-center align-items-center ml-1'>
                            <div className='row col-12 d-flex justify-content-center align-items-center user_name'>
                                {localStorage.getItem("username")}
                            </div>
                            <div className='row col-12 d-flex justify-content-center align-items-center user_role'>
                                {localStorage.getItem("role")}
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'></div>
                    <div>
                        <hr className="dropdown-divider" />
                    </div>
                    <div className='row mb-2'></div>
                    <div className='col-12 row d-flex justify-content-center align-items-center'>
                        <div className='col-1'></div>
                        <div className='col-3'><SettingOutlined style={{fontSize:"28px",cursor:"pointer"}}/></div>
                        <div className='reset_button text-left col-8' onClick={this.showReset} style={{ cursor: "pointer", padding: "5px" }}>Reset Password</div>
                    </div>
                    <Modal
                        title="Reset Your Password"
                        visible={reset_button}
                        okButtonProps={{ style: { display: !validate ? 'none' : '' } }}
                        onOk={this.resetStart}
                        onCancel={this.resetAbort}
                    >
                        {passwordMessage && <span className={passwordMessage.status ? 'text-success' : 'text-warning'}>{passwordMessage.message}</span>}
                        {this.createField("oldPassword", data.oldPassword, error.oldPassword, "Enter Your Old Password")}
                        {this.createField("newPasword", data.newPasword, error.newPasword, "Enter Your New Password")}
                        {this.createField("retypePassword", data.retypePassword, error.retypePassword, "Reconfirm New Password")}
                    </Modal>
                    <div className='row mt-2'></div>
                    <div>
                        <hr className="dropdown-divider" />
                    </div>
                    <div className='row mt-2'></div>
                    <div className='col-12 row d-flex justify-content-between align-items-center'>
                    <div className='col-1'></div>
                    <div className='col-3'><LogoutOutlined style={{fontSize:"28px",cursor:"pointer"}} onClick={this.removeToken}/></div>
                        
                        <div className='reset_button text-left col-8' onClick={this.removeToken} style={{ cursor: "pointer", padding: "5px" }}>Sign Out</div>
                    </div>
                </div>


            </>
        )
    }
}

export default UserProfile