import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import moment from "moment";

const role=localStorage.getItem("role");
localStorage.setItem("cat_list_page",1);
localStorage.setItem("cat_ass_page",1);
localStorage.setItem("cat_desc_page",1)


export const AppViews = () => {
  useEffect(()=>{
    let current_date=moment().format("DD-MM-YYYY");
    let login_date = localStorage.getItem("login_date")
    if(!localStorage.getItem("login_date")){
      localStorage.removeItem("auth_token");
      window.location.pathname="/auth/login";
    }
    else if(localStorage.getItem("login_date")!==current_date){
      localStorage.removeItem("auth_token");
      window.location.pathname="/auth/login";
    }
  })
  console.log(APP_PREFIX_PATH)
  return (
    <Suspense fallback={<Loading cover="content" />}>
      {(role==="Manager"||role==="Editor"|| role==="Reviewer")?<Switch>
        {/* <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./Dashboard`))}
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./EditorsView`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/category_processing`}
          component={lazy(() => import(`./category processing`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/category_description`}
          component={lazy(() => import(`./category_description`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/description_board`}
          component={lazy(() => import(`./description_board`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/category`}
          component={lazy(() => import(`./category`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/product`}
          component={lazy(() => import(`./product`))}
        />
         <Route
          path={`${APP_PREFIX_PATH}/research`}
          component={lazy(() => import(`./Research_Blog`))}
        />
         <Route
          path={`${APP_PREFIX_PATH}/chat`}
          component={lazy(() => import(`./chat`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/bvrblog`}
          component={lazy(() => import(`./BVR_Blog/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sem_landing_page_1`}
          component={lazy(() => import(`./SEM_Landing_Page/Landing_Page_1/index_1`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sem_landing_page_2`}
          component={lazy(() => import(`./SEM_Landing_Page/Landing_Page_2/index_2`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sem_report`}
          component={lazy(() => import(`./SEM_Report/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/add_category`}
          component={lazy(() => import(`./add_category/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/amazon_product`}
          component={lazy(() => import(`./Add_Amazon_Product/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/scrumboard_report`}
          component={lazy(() => import(`./scrumboard_report/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/add_jungle_scout_data`}
          component={lazy(() => import(`./Jungle_Scout/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mixcatepage`}
          component={lazy(() => import(`./Mix_Category_Page/index`))}
        />
         <Route
          path={`${APP_PREFIX_PATH}/m2homepage`}
          component={lazy(() => import(`./Home_Page_Modular2/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/popular_categories`}
          component={lazy(() => import(`./popular_categories/index`))}
        />
         <Route
          path={`${APP_PREFIX_PATH}/homepagetheme`}
          component={lazy(() => import(`./Home_Page_Theme/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cta_qa_list`}
          component={lazy(() => import(`./CTA_QA/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/homepagead`}
          component={lazy(() => import(`./Home_Page_Ad_Banner/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/textSummary`}
          component={lazy(() => import(`./Text-Summary`))}
        />
          <Route
          path={`${APP_PREFIX_PATH}/contextsummary`}
          component={lazy(() => import(`./ContextSummary/index`))}
        />
        
        <Route
          path={`${APP_PREFIX_PATH}/moveproducts`}
          component={lazy(() => import(`./MoveProducts/index`))}
        />
         <Route
          path={`${APP_PREFIX_PATH}/dailysales`}
          component={lazy(() => import(`./DailySalesProducts/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/ebayqa`}
          component={lazy(() => import(`./eBay_Q_A/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/childcategory`}
          component={lazy(() => import(`./ChildCategory/index`))}
        />
         <Route
          path={`${APP_PREFIX_PATH}/delete_asins`}
          component={lazy(() => import(`./Delete_Asins/index`))}
        />
          <Route
          path={`${APP_PREFIX_PATH}/product_report`}
          component={lazy(() => import(`./Product_Report/index`))}
        /> 
        <Route
          path={`${APP_PREFIX_PATH}/missing_top_pros_cons`}
          component={lazy(() => import(`./Missing_Pros_Cons/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/soldproductdtails`}
          component={lazy(() => import(`./Upload_Sold_Details/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/category_blog_js`}
          component={lazy(() => import(`./Category_Blog_JS/index`))}
        />
          <Route
          path={`${APP_PREFIX_PATH}/openaiprompt`}
          component={lazy(() => import(`./OpenAIC/index`))}
        />
         <Route
          path={`${APP_PREFIX_PATH}/facetcontrol`}
          component={lazy(() => import(`./Facet Control/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/elastic_search`}
          component={lazy(() => import(`./ElasticSearch/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/daily-deals-product-qa`}
          component={lazy(() => import(`./DailySalesProductQA/index`))}
        />
         <Route
          path={`${APP_PREFIX_PATH}/manage_bread_crumb`}
          component={lazy(() => import(`./CategoryBreadCrumb/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/homepagefooter`}
          component={lazy(() => import(`./HomePageFooterHead/addEditHomePageFooterHeader`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/affiliate_product_mapping_qa`}
          component={lazy(() => import(`./Product_Maping_QA/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/merchant_priority`}
          component={lazy(() => import(`./merchant_priority/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/manage_deals_page`}
          component={lazy(() => import(`./Deals_Page/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/editable_bvr_product_page`}
          component={lazy(() => import(`./BVR_Product_Page/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/blacklist_page`}
          component={lazy(() => import(`./Blacklist_Component/index`))}
        />
        <Route
        path={`${APP_PREFIX_PATH}/unzip_folder_on_remote_machine`}
        component={lazy(() => import(`./Unzip_Folder_Functionality/FileUnzipCombined`))}
        />
        <Route
        path={`${APP_PREFIX_PATH}/create_aspect_and_tagline`}
        component={lazy(() => import(`./Create_aspect_and_tagline/CreateAspectAndTaglineCombined`))}
        />

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/category/category_list`} />
      </Switch>:
      <Switch>
       <Route
          path={`${APP_PREFIX_PATH}/category_processing`}
          component={lazy(() => import(`./category processing`))}
        />
<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/category_processing`} />
    </Switch>  
    }
    </Suspense>
  );
};

export default React.memo(AppViews);
