import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import "./views/app-views/table.css";
// Sentry.init({
//     dsn: "https://032bea1fa10c4df6a228d3b48ac7f1a8@o1090350.ingest.sentry.io/6107718",
//     integrations: [new Integrations.BrowserTracing()],
  
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
