import React, { Component } from "react";
// import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import { LogoutOutlined } from "@ant-design/icons";
import { Drawer, Menu } from "antd";
import { connect } from "react-redux";

export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  signOut = () => {
    localStorage.removeItem("auth_token");
    window.location = "/auth";
  };

  render() {
    return (
      <>
        <Menu mode="horizontal">
          <Menu.Item onClick={(e) => this.signOut()}>
            <span>
              <LogoutOutlined className="mr-1" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
          {/* <Menu.Item onClick={this.showDrawer}>
            <SettingOutlined className="nav-icon mr-0" />
          </Menu.Item> */}
        </Menu>
        <Drawer
          title="Theme Config"
          placement="right"
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          NavPanel Contents here!
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(NavPanel);
